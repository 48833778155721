//
// FileName index
// ProjectName pingdu-pc
// ComputerUser gys
// Author 区区电脑
// Email 1573580882@qq.com
// Date 2021/3/14 9:38 上午
// Copyright © 2021 gys. All rights reserved.

import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        businessList: [],
        userInfo: {},
    },
    // modules: {},
    mutations: {
        businessList(state, value) {
            state.businessList = value;
        },
        userInfo(state, value) {
            state.userInfo = value;
        }
    },
    actions: {
        businessList({commit}, value) {
            commit("businessList", value)
        },
        userInfo({commit}, value) {
            commit("userInfo", value)
        }
    }
})
