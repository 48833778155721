//
// FileName windowScroll
// ProjectName pingdu-pc
// ComputerUser gys
// Author 区区电脑
// Email 1573580882@qq.com
// Date 2021/3/7 5:00 下午
// Copyright © 2021 gys. All rights reserved.

window.scrollEvents = {};

window.onscroll = () => {
    let scrollTop = window.document.documentElement.scrollTop;
    for (let key in window.scrollEvents) {
        let event = window.scrollEvents[key];
        event(scrollTop);
    }
}

window.addScrollEvent = (callback) => {
    const id = Math.floor(Math.random()*999);
    window.scrollEvents[id] = callback;
    return id;
}
