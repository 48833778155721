import { render, staticRenderFns } from "./FModal.vue?vue&type=template&id=3528d2e5&scoped=true&"
import script from "./FModal.vue?vue&type=script&lang=js&"
export * from "./FModal.vue?vue&type=script&lang=js&"
import style0 from "./FModal.vue?vue&type=style&index=0&id=3528d2e5&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3528d2e5",
  null
  
)

export default component.exports