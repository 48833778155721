<template>
    <div class="user flex-col flex-j-start flex-a-center">
        <div class="user-center flex-col flex-j-start flex-a-center">
            <span class="home" @click="onBackHome">
                <a class="iconfont icon-iconfontzhizuobiaozhun023101"></a>
                首页
            </span>
            <div class="avatar">
                <img :src="userInfo.HeadImage" alt=""/>
                <span @click="onUploadClick" class="icon iconfont icon-shangchuan"></span>
            </div>
            <div class="info">
                <div class="flex-row flex-j-between flex-a-center">
                    <span class="flex-1">企业名称：{{ userInfo.CompayName || '--' }}</span>
                    <span class="flex-1">行业：{{ userInfo.BusinessName }}</span>
                    <span class="flex-1">所属镇街：{{ userInfo.AreaName }}</span>
                    
                </div>
                <br>
                <div class="flex-row flex-j-between flex-a-center">
                    <span class="flex-1">联系人：{{ userInfo.MemberName }}
                        <a v-if="userInfo.Sex==1" class="iconfont icon-nanxingmale209" style="color: #39a9ed"></a>
                        <a v-if="userInfo.Sex==2" class="iconfont icon-nv" style="color: #f1676d"></a>
                    </span>
                    <span class="flex-1">联系电话：{{ userInfo.Phone || '--' }}</span>
                    <span class="flex-1">公司地址：{{ userInfo.CompayAdd || '--' }}</span>
<!--                    <span class="flex-1">邮箱：{{ userInfo.Email || '&#45;&#45;' }}</span>-->
                </div>
                <br>
                <div class="flex-row flex-j-between flex-a-center">
                    
                    <span class="flex-1"></span>
                    <span class="flex-1"></span>
                    <div class="flex-1">
                        <div @click="onCompanyClick" class="flex-row flex-j-start flex-a-center tap"
                             style="cursor: pointer;display: inline-flex;">
                            <span class="iconfont icon-bianji" style="font-size: 0.46rem;margin-right: 0.08rem;"></span>
                            <span>编辑信息</span>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
        <div v-if="userInfo.MemberType == 2">
            <empty-page msg="企业审核中"/>
        </div>
        <left-tab v-else :tabs="tabs" @change="onTabChange" :active="tabsActive">
            <router-view/>
        </left-tab>
        <company-edit ref="companyEdit"/>
    </div>
</template>

<script>
// user
import LeftTab from "@/components/left-tab";
import EmptyPage from "@/components/empty-page";
import CompanyEdit from "@/pages/company/company-edit";

export default {
    name: "company",
    components: {
        CompanyEdit,
        EmptyPage,
        LeftTab
    },
    filters: {
        Marriage: function (value) {
            console.log(value)
            if (value == 0) {
                return '保密';
            } else if (value == 1) {
                return '未婚';
            } else if (value == 2) {
                return '已婚'
            }
        }
    },
    data() {
        return {
            tabsActive: 0,
            tabs: [{
                title: "招聘职位管理",
                value: 1,
                name: 'office-manage'
            }, {
                title: "余缺调剂管理",
                value: 2,
                name: 'adjust-manage'
            }, {
                title: "企业账号转移",
                name: 'company-transfer',
            },{
                title: '设置登录密码',
                name: 'set-password'
            }],
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo || {};
        },
        routerName() {
            return this.$route.name;
        }
    },
    mounted() {
        this.tabsActive = this.tabs.findIndex(item => item.name == this.routerName);
    },
    activated() {
        this.tabsActive = this.tabs.findIndex(item => item.name == this.routerName);
    },
    methods: {
        onCompanyClick() {
            this.$refs['companyEdit'].show({
                params: {
                    id: this.userInfo.Id
                },
                onOk() {
                    console.log('提交结束')
                },
                onCancel() {
                    console.log('取消')
                }
            })
        },
        onTabChange(index) {
            console.log(index)
            this.tabsActive = index;
            const item = this.tabs[index];
            if (item.name) {
                this.$router.push(`/company/${item.name}`)
            }
            
            // this.pageIndex = 1;
            // this.list = [];
            // this.loadPageList();
        },
        onBackHome() {
            this.$router.replace('/')
        },
        async onUploadClick() {
            const res = await $app.fileUpload(2);
            const params = {
                ...this.userInfo,
            };
            params.HeadImage = res.data;
            const updaterRes = await $app.httpPost({
                path: '/Member/UpdateMemberInfo',
                params: params,
                loading: '...',
            });
            if (updaterRes.ok) {
                const r = await $app.httpGet({
                    path: `/Member/GetUserInfo`,
                    params: {id: params.Id},
                });
                if (r.ok) {
                    $app.setStorageSync('userInfo', r.data);
                }
            }
        }
    }
}
</script>

<style scoped lang="less">
.user {
    & .user-center {
        width: calc(22rem);
        background-color: #4eabe8;
        padding: 0.4rem;
        border-radius: 0.12rem;
        margin: 0.2rem 0;
        box-sizing: border-box;
        box-shadow: 0 0 0.2rem 0.1rem rgba(104, 219, 206, 0.2);
        position: relative;
        
        & .home {
            position: absolute;
            left: 0.4rem;
            top: 0.4rem;
            color: #2c3e50;
            cursor: pointer;
            
            &:active {
                opacity: 0.9;
            }
            
            & .iconfont {
                color: #2c3e50;
            }
        }
        
        & .avatar {
            width: 3.0rem;
            height: 3.0rem;
            border-radius: 0.2rem;
            border: 0.04rem solid #fff;
            position: relative;
            overflow: hidden;
            
            & img {
                width: 100%;
                height: 100%;
                border-radius: 0.2rem;
            }
            
            & .icon {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                text-align: center;
                line-height: 3.0rem;
                font-size: 0.8rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1;
                opacity: 0;
                transition: all 0.3s ease;
            }
            
            &:hover {
                & .icon {
                    opacity: 1;
                }
            }
        }
        
        & .info {
            margin-top: 0.4rem;
            width: 16rem;
            color: #fff;
            text-align: left;
            font-weight: 600;
        }
        
    }
}
</style>
