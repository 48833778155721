//
// FileName remSize
// ProjectName pubaccount
// ComputerUser gys
// Author 区区电脑
// Email 1573580882@qq.com
// Date 2021/1/26 12:25 下午
// Copyright © 2021 gys. All rights reserved.
const setDocumentFontSizeWithSize = (size) => {
    document.getElementById("g-html").style.fontSize = size + "px";
};
const startSetDocumentFontSize = () => {
    // 基准宽度
    let baseWidth = 1400; // 1rem = 100px
    let maxWidth = 1300; // 最大屏幕宽度 414 iphone max
    
    let screenW = window.document.documentElement.clientWidth;
    screenW = Math.min(screenW, maxWidth);
    let shouldSize = screenW / (baseWidth / 50); // 目标字体
    setDocumentFontSizeWithSize(shouldSize);
    
    // let realSize = parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('font-size')); // 受系统字体大小影响后的实际大小
    // let size = shouldSize / realSize * shouldSize; // 根据比例修改牧宝字体大小 （仅限Android, iOS 见 index.html body样式）
    // setDocumentFontSizeWithSize(size);
};

export const remSize = () => {
    startSetDocumentFontSize();
    window.onresize = () => {
        startSetDocumentFontSize();
    };
};
