<template>
    <div class="component flex-row flex-j-between flex-a-center">
        <div class="flex-1">
            <div class="flex-row flex-j-start flex-a-center">
                <div class="title">{{
                        item.PostName }}
                    <span style="color: gray;font-size: .28rem;">{{ item.NeedNum }}名</span>
                </div>
                <span v-if="item.SalaryMin <= 0 || item.SalaryMax <= 0" class="price">薪资面议</span>
                <span v-else
                      class="price">{{ item.SalaryMin }}~{{ item.SalaryMax }}
            </span>
            </div>
            
            <div class="flex-row flex-j-start flex-a-center conditions welfares" style="flex-wrap: wrap;width: 100%">
                <!--                <div class="conditions">-->
                <div v-for="(requirement, index) in item.RequirementsList" :key="index"
                     class="condition">
                    <div v-if="index<3" class="icon"></div>
                    <span v-if="index<3">{{ requirement }}</span>
                </div>
                <!--                </div>-->
                
                <!--                <div class="welfares">-->
                <div v-for="(welfare, i) in item.WelfareList" :key="`_${i}`" class="welfare"
                     v-show="i<3">{{
                        welfare
                    }}
                </div>
                <!--                </div>-->
            </div>
            <div class="flex-row flex-j-betweem flex-a-center">
                <div class="time flex-1">{{ item.CompanyName }}</div>
                <div class="time">{{ item.upTimespan }}</div>
            </div>
        </div>
        <!--        <div class="right"></div>-->
        <div class="btn-group">
            <span class="btn edit" @click="$emit('edit', item)">编辑</span>
            <span class="btn delete" @click="$emit('delete', item)">删除</span>
        </div>
    </div>
</template>

<script>
// office-list-cell
export default {
    name: "office-manage-cell",
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onOfficeClick() {
            this.$router.push(`/office-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.component {
    padding: .20rem 0.4rem;
    background-color: #fff;
    //margin-bottom: .20rem;
    border-radius: .12rem;
    width: 100%;
    text-align: left;
    //display: inline-block;
    //margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    box-sizing: border-box;
    transition: all 0.3s ease;
    position: relative;
    & .btn-group {
        opacity: 0;
        position: absolute;
        right: 0;
        bottom: 0.1rem;
        z-index: 10;
        font-size: 0.36rem;
        & .btn {
            padding: 0.1rem 0.2rem;
            &:hover {
                cursor: pointer;
                font-size: 0.4rem;
            }
        }
        & .edit {
            color: #39a9ed;
        }
        & .delete {
            color: #f1676d;
        }
    }
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
        & .btn-group {
            opacity: 1;
        }
    }
}

.component .title {
    font-size: .4rem;
    color: #333333;
    padding: 0.2rem 0;
}

.component .price {
    color: #ee4343;
    margin-left: 0.4rem;
}

.conditions {
    padding-bottom: .08rem;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    span-overflow: ellipsis;
}

.condition {
    /* height: .40rem; */
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: .32rem;
    margin-right: .30rem;
    color: orange;
}

.condition .icon {
    width: .06rem;
    height: .20rem;
    background-color: orange;
    margin-right: .04rem;
    border-radius: .10rem;
}

.welfares {
    white-space: nowrap;
    overflow: hidden;
    span-overflow: ellipsis;
}

.welfare {
    font-size: .32rem;
    color: #4eabe8;
    border: 1px solid #4eabe8;
    padding: .04rem .10rem;
    display: inline-block;
    margin-right: .20rem;
    border-radius: .06rem;
    margin-bottom: .04rem;
}


.component:active {
    opacity: 0.7;
}

.time {
    font-size: .28rem;
    color: #999999;
    /* span-align: right; */
    white-space: nowrap;
    overflow: hidden;
    span-overflow: ellipsis;
    /* line-height: 0; */
    /* padding-bottom: .20rem; */
    /* margin-top: .20rem; */
}
& .right {
    width: 8.0rem;
}
</style>
