<template>
    <div id="div1" class="FEditor">
    </div>
</template>

<script>
// FEditor
import E from 'wangeditor';

export default {
    name: "FEditor",
    props: {
        value: {
            type: String,
            default: ""
        },
        height: {
            type: Number,
            default: 400
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            editor: {}
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const __this = this;
            setTimeout(() => {
                const editor = new E('#div1');
                editor.config.height = this.height;
                editor.config.showLinkImg = false;
                editor.config.menus = [
                    'head',
                    'bold',
                    'fontSize',
                    'fontName',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'indent',
                    'lineHeight',
                    'foreColor',
                    'backColor',
                    'link',
                    'list',
                    'todo',
                    'justify',
                    'quote',
                    'emoticon',
                    'image',
                    // 'video',
                    'table',
                    'code',
                    'splitLine',
                    'undo',
                    'redo',
                ];
                editor.config.customUploadImg = async function (resultFiles, insertImgFn) {
                    // 在此自定义上传图片到服务器功能
                    for (let i = 0; i < resultFiles.length; i++) {
                        const res = await $app.fileUploadWithFile(2, resultFiles[i]);
                        if (res.ok) {
                            insertImgFn(`${$app.staticHost}${res.data}`)
                        }
                    }
                }
                editor.config.onchange = function (newHtml) {
                    __this.$emit('change', newHtml);
                }
                
                editor.create()
                if (__this.value) {
                    editor.txt.html(__this.value);
                }
                __this.editor = editor;
            }, 100)
        }
    },
    watch: {
        value() {
            this.editor.txt.html(this.value);
        }
    }
}
</script>

<style scoped lang="less">
.FEditor {
    width: 100%;
}
</style>
