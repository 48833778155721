<template>
    <div class="index">
        <index-header/>
        <div class="br"></div>
        <div class="router flex-row flex-j-center flex-a-start">
<!--            <app-left/>-->
            <router-view/>
<!--            <app-right/>-->
        </div>
    </div>
</template>

<script>
// index
import IndexHeader from "@/components/index-header";
// import AppLeft from "@/components/app-left";
// import AppRight from "@/components/app-right";

export default {
    name: "index",
    components: {
        // AppRight, AppLeft,
        IndexHeader},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {}
}
</script>

<style scoped lang="less">
.index {
    //padding-top: 7.2rem;
    width: 100vw;
    & .router {
        //width: 28rem;
    }
}
</style>
