<template>
    <div class="info">
        <div class="button-group flex-row flex-j-start flex-a-center">
            <div @click="onEditClick" class="button tap" style="background-color: #39a9ed">编辑</div>
            
            <div v-if="userInfo.IsShow" @click="onCloseClick" class="button tap" style="background-color: #f1676d">
                关闭求职信息展示</div>
            <div v-else @click="onOpenClick" class="button tap" style="background-color: #68dbce">
                开启求职信息展示</div>
            <div @click="onCompanyClick" class="button tap" style="background-color: orange">企业认证</div>
        </div>
        <div class="card">
            <div class="flex-row flex-j-between flex-a-center">
                <span class="flex-1">期望职位：{{userInfo.PostName || '--'}}</span>
                <span class="flex-1">镇街：{{userInfo.AreaName || '--'}}</span>
                <span class="flex-1"></span>
            </div>
            <br>
            <div class="flex-col flex-j-start flex-a-start">
                <div class="flex-1" style="font-weight: 600;">个人简介</div>
                <div class="Details" style=""
                     v-html="userInfo.Details"></div>
            </div>
        </div>
        <info-edit ref="infoEdit"/>
        <company-edit ref="companyEdit"/>
    </div>
</template>

<script>
// info
import InfoEdit from "@/pages/user/info-edit";
import CompanyEdit from "@/pages/company/company-edit";
export default {
    name: "info",
    components: {CompanyEdit, InfoEdit},
    data() {
        return {}
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo || {};
        },
    },
    mounted() {
    },
    methods: {
        onCompanyClick() {
            const __this = this;
            this.$refs['companyEdit'].show({
                params: {
                    id: this.userInfo.Id
                },
                onOk() {
                    __this.$router.replace('/company/office-manage')
                },
                onCancel() {console.log('取消')}
            })
        },
        onEditClick() {
            this.$refs['infoEdit'].show({
                params: {
                    id: this.userInfo.Id
                },
                onOk() {console.log('提交结束')},
                onCancel() {console.log('取消')}
            })
        },
        onOpenClick() {
            const __this = this;
            this.$confirm({
                title: '开启求职信息展示?',
                content: '开启求职信息展示后，企业将从招聘列表中查看到您',
                okText: '开启',
                okType: 'primary',
                cancelText: '取消',
                onOk() {
                    console.log('OK');
                    __this.openShow();
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        async openShow() {
            const params = {
                ...this.userInfo,
            };
            params.IsShow = true;
            const res = await $app.httpPost({
                path: '/Member/UpdateMemberInfo',
                params: params,
                loading: '...',
            });
            if (res.ok) {
                const r = await $app.httpGet({
                    path: `/Member/GetUserInfo`,
                    params: {id: params.Id},
                });
                if (r.ok) {
                    $app.setStorageSync('userInfo', r.data);
                }
                $app.showToast({
                    title: '开启成功',
                });
            }
            
        },
        onCloseClick() {
            const __this = this;
            this.$confirm({
                title: '关闭求职信息展示?',
                content: '关闭求职信息展示后，企业将不能再从招聘列表中查看到您',
                okText: '关闭',
                okType: 'primary',
                cancelText: '取消',
                onOk() {
                    console.log('OK');
                    __this.closeShow();
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        async closeShow() {
            const params = {
                ...this.userInfo,
            };
            params.IsShow = false;
            const res = await $app.httpPost({
                path: '/Member/UpdateMemberInfo',
                params: params,
                loading: '...',
            });
            if (res.ok) {
                const r = await $app.httpGet({
                    path: `/Member/GetUserInfo`,
                    params: {id: params.Id},
                });
                if (r.ok) {
                    $app.setStorageSync('userInfo', r.data);
                }
            }
        
        }
    }
}
</script>

<style scoped lang="less">
.info {
    width: 17.6rem;
    text-align: left;
    & .button-group {
        margin-bottom: 0.2rem;
        & .button {
            margin-right: 0.4rem;
            color: #fff;
            padding: 0.16rem 0.4rem;
            border-radius: 0.12rem;
            cursor: pointer;
        }
    }
    & .card {
        background-color: #fff;
        border-radius: 0.12rem;
        padding: 0.4rem;
    }
}
</style>
