//
// FileName router
// ProjectName pubaccount
// ComputerUser gys
// Author 区区电脑
// Email 1573580882@qq.com
// Date 2021/1/26 12:29 下午
// Copyright © 2021 gys. All rights reserved.
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Vue from "vue";
import VueRouter from "vue-router";
import Index from '@/pages/index';
import Home from '@/pages/home';
import User from '@/pages/user/user';
import UserInfo from '@/pages/user/info';
import Company from '@/pages/company/company';
import OfficeManage from '@/pages/company/office-manage';

Vue.use(VueRouter)

const router = new VueRouter({
    // mode: "history",
    routes: [
        {
            path: '/',
            redirect: '/index/home'
        },
        {
            path: '/index',
            component: Index,
            meta: {
                title: '主页',
                keepAlive: true
            },
            children: [
                {
                    path: 'home',
                    name: 'home',
                    component: Home,
                    meta: {
                        title: '首页',
                        keepAlive: true
                    }
                },
                {
                    path: 'office',
                    name: 'office',
                    component: () => import('@/pages/office'),
                    meta: {
                        title: '求职',
                        keepAlive: true
                    }
                },
                {
                    path: 'hire',
                    name: 'hire',
                    component: () => import('@/pages/hire'),
                    meta: {
                        title: '招聘',
                        keepAlive: true
                    }
                },
                {
                    path: 'train',
                    name: 'train',
                    component: () => import('@/pages/train'),
                    meta: {
                        title: '培训',
                        keepAlive: true
                    }
                },
                {
                    path: 'start-policy',
                    name: 'start-policy',
                    component: () => import('@/pages/start-policy'),
                    meta: {
                        title: '创业',
                        keepAlive: true
                    }
                },
                {
                    path: 'organize',
                    name: 'organize',
                    component: () => import('@/pages/organize'),
                    meta: {
                        title: '职业指导',
                        keepAlive: true
                    }
                },
                {
                    path: 'jobfair',
                    name: 'jobfair',
                    component: () => import('@/pages/jobfair'),
                    meta: {
                        title: '招聘会',
                        keepAlive: true
                    }
                },
                {
                    path: 'work',
                    name: 'work',
                    component: () => import('@/pages/work'),
                    meta: {
                        title: '零工之家',
                        keepAlive: true
                    }
                },
                {
                    path: 'jobmatch',
                    name: 'jobmatch',
                    component: () => import('@/pages/jobmatch'),
                    meta: {
                        title: '人岗匹配',
                        keepAlive: true
                    }
                },
                {
                    path: 'adjust',
                    name: 'adjust',
                    component: () => import('@/pages/adjust'),
                    meta: {
                        title: '余缺调剂',
                        keepAlive: true
                    }
                },
                {
                    path: 'mine',
                    name: 'mine',
                    component: () => import('@/pages/mine'),
                    meta: {
                        title: '个人中心',
                        keepAlive: true
                    }
                },
                {
                    path: 'policy',
                    name: 'policy',
                    component: () => import('@/pages/policy'),
                    meta: {
                        title: '通知公告',
                        keepAlive: true
                    }
                },
            ]
        },
        {
            path: '/user',
            component: User,
            meta: {
                title: '个人中心',
                // keepAlive: true
            },
            children: [
                {
                    path: 'info',
                    name: 'info',
                    component: UserInfo,
                    meta: {
                        title: '个人中心',
                        // keepAlive: true
                    }
                },
                {
                    path: 'work-manage',
                    name: 'work-manage',
                    component: () => import('@/pages/user/work-manage'),
                    meta: {
                        title: '零工之家',
                        // keepAlive: true
                    }
                },
                {
                    path: 'set-password',
                    name: 'set-password',
                    component: () => import('@/pages/user/set-password'),
                    meta: {
                        title: '设置登录密码',
                        // keepAlive: true
                    }
                }
            ]
        },
        {
            path: '/company',
            component: Company,
            meta: {
                title: '企业中心',
                // keepAlive: true
            },
            children: [
                {
                    path: 'office-manage',
                    name: 'office-manage',
                    component: OfficeManage,
                    meta: {
                        title: '职位管理',
                        // keepAlive: true
                    }
                },
                {
                    path: 'adjust-manage',
                    name: 'adjust-manage',
                    component: () => import('@/pages/company/adjust-manage'),
                    meta: {
                        title: '余缺调剂管理',
                        // keepAlive: true
                    }
                },
                {
                    path: 'company-transfer',
                    name: 'company-transfer',
                    component: () => import('@/pages/company/company-transfer'),
                    meta: {
                        title: '企业账号转移',
                        // keepAlive: true
                    }
                },
                {
                    path: 'set-password',
                    name: 'set-password',
                    component: () => import('@/pages/company/set-password'),
                    meta: {
                        title: '设置登录密码',
                        // keepAlive: true
                    }
                }
                
            ]
        },
        {
            path: '/office-detail',
            name: 'office-detail',
            component: () => import('@/pages/detail/office-detail'),
            meta: {
                title: '职位详情',
                keepAlive: false
            }
        },
        {
            path: '/work-detail',
            name: 'work-detail',
            component: () => import('@/pages/detail/work-detail'),
            meta: {
                title: '零工之家详情',
                keepAlive: false
            }
        },
        {
            path: '/adjust-detail',
            name: 'adjust-detail',
            component: () => import('@/pages/detail/adjust-detail'),
            meta: {
                title: '余缺调剂详情',
                keepAlive: false
            }
        },
        {
            path: '/organize-detail',
            name: 'organize-detail',
            component: () => import('@/pages/detail/organize-detail'),
            meta: {
                title: '机构详情',
                keepAlive: false
            }
        },
        {
            path: '/train-detail',
            name: 'train-detail',
            component: () => import('@/pages/detail/train-detail'),
            meta: {
                title: '培训详情',
                keepAlive: false
            }
        },
        {
            path: '/start-policy-detail',
            name: 'start-policy-detail',
            component: () => import('@/pages/detail/start-policy-detail'),
            meta: {
                title: '创业详情',
                keepAlive: false
            }
        },
        {
            path: '/policy-detail',
            name: 'policy-detail',
            component: () => import('@/pages/detail/policy-detail'),
            meta: {
                title: '政策资讯详情',
                keepAlive: false
            }
        },
        {
            path: '/hire-detail',
            name: 'hire-detail',
            component: () => import('@/pages/detail/hire-detail'),
            meta: {
                title: '招聘用户详情',
                keepAlive: false
            }
        },
        {
            path: '/jobfair-detail',
            name: 'jobfair-detail',
            component: () => import('@/pages/detail/jobfair-detail'),
            meta: {
                title: '招聘会详情',
                keepAlive: false
            }
        },
        {
            path: '/jobfair-detail-list',
            name: 'jobfair-detail-list',
            component: () => import('@/pages/detail/jobfair-detail-list'),
            meta: {
                title: '招聘会会场职位列表',
                keepAlive: false
            }
        },
        {
            path: '/zhizhao',
            name: 'zhizhao',
            component: () => import('@/pages/detail/zhizhao'),
            meta: {
                title: '执照',
                keepAlive: false
            }
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

const checkoutLogin = (to, from, next) => {
    return new Promise(resolve => {
        const needs = [
            '/index/train',
        ];
        if (needs.indexOf(to.fullPath) > -1) {
            const token = $app.getToken();
            if (!token && to.fullPath !== '/index/home') {
                $app.showToast({
                    title: '请先登录',
                    icon: 'none'
                })
                next({
                    path: '/'
                });
            } else {
                resolve();
            }
        } else {
            resolve();
        }
    })
}

router.beforeEach(async (to, from, next) => {
    NProgress.start();
    await checkoutLogin(to, from, next);
    next();
})
router.afterEach(() => {
    setTimeout(() => {
        NProgress.done();
    }, 300)
})
export default router;
