<template>
    <div class="information">
        <div class="title" style="font-size: 0.4rem;line-height: 0.8rem;margin-bottom: 0.4rem;">通知公告</div>
        <div v-for="(item, index) in list" :key="index" class="cell flex-row flex-j-between flex-a-center"
             @click="onClick(item)">
            <span class="flex-1 title">{{item.TitleName}}</span>
            <span class="time">{{item.upTimeText}}</span>
        </div>

    </div>
</template>

<script>
// information
export default {
    name: "information",
    data() {
        return {
            list: [],
            pageIndex: 1
        }
    },
    mounted() {
        this.loadList();
    },
    methods: {
        async loadList() {
            // 是否是加载更多
            const isLoadMore = this.pageIndex > 1;
            const params = {
                pageIndex: this.pageIndex,
                pageSize: 15,
                policyType: 5,
                sort: 'PolicyDate',
                sortType: 'desc',
            };
            const res = await $app.httpGet({
                path: `/BasePolicyInfo/GetPolicyList`,
                params: params,
            });

            let oldList = this.list;
            let newList = res.data || [];
            newList = newList.map(item => {
                item.upTimeText = $app.dateFormatChinese(item.PolicyDate);
                return item;
            })
            if (isLoadMore) {
                oldList = oldList.concat(newList);
            } else {
                oldList = newList;
            }
            this.list = oldList;
        },
        onClick(item) {
            this.$router.push(`/policy-detail?id=${item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.information {
    width: calc(6rem);
    background: #fff;
    padding: 0.2rem;
    box-sizing: border-box;
    height: 8.0rem;
    overflow: auto;
    position: relative;
    text-align: center;
    & .title {
        background: #4eabe8;
        color: #fff;
    }
    & .cell {
        padding: 0.2rem;
        cursor: pointer;
        height: 0.8rem;
        color: #666;
        &:hover {
            color: #39a9ed;
        }
        & .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            background: unset;
            color: unset;
        }
        & .time {
            font-size: 0.28rem;
            color: #999;
        }
    }
}
</style>
