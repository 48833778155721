<template>
    <div class="office-manage">
        <div class="button-group flex-row flex-j-start flex-a-center">
            <div @click="onEditClick({})" class="button tap" style="background-color: #39a9ed">发布职位</div>
        </div>
        <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
            <empty-page v-if="!list||list.length===0" msg="暂未发布职位信息"/>
            <div class="list" style="width: 100%;padding: 0 0.1rem;box-sizing: border-box;">
                <office-manage-cell v-for="(item, index) in list" :key="index" :item="item"
                                  @edit="onEditClick" @delete="onDeleteClick"></office-manage-cell>
            </div>
        </refresh-list>
        <office-edit ref="infoEdit"/>
    </div>
</template>

<script>
// office-manage
import RefreshList from "@/components/refresh-list";
import EmptyPage from "@/components/empty-page";
import OfficeManageCell from "@/components/office-manage-cell";
import OfficeEdit from "@/pages/company/office-edit";
export default {
    name: "office-manage",
    components: {OfficeEdit, OfficeManageCell, EmptyPage, RefreshList},
    data() {
        return {
            list: [],
            pageSize: 15,
            pageIndex: 1,
            searchParams: {
                postName: "",
                // keyword: ""
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    mounted() {
        this.loadPageList();
        // 监听头部搜索框搜索事件
        window.$onIndexHeaderSearch = (keyword) => {
            this.searchParams.postName = keyword;
            // this.searchParams.keyword = keyword;
            // console.log(keyword)
            this.pageIndex = 1;
            this.list = [];
            this.loadPageList();
        }
    },
    methods: {
        onEditClick(item = {}) {
            const __this = this;
            this.$refs['infoEdit'].show({
                params: item,
                onOk() {
                    __this.pageIndex = 1;
                    __this.loadPageList();
                },
                onCancel() {console.log('取消')}
            })
        },
        onDeleteClick(item = {}) {
            const __this = this;
            this.$confirm({
                title: '您确认要删除当前数据吗?',
                content: '',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    console.log('OK');
                    __this.deleteData(item);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        async deleteData(item) {
            const params = {
                ids: item.Id
            };
            const res = await $app.httpGet({
                path: `/BasePostInfo/DeletePostInfo`,
                params: params,
                loading: '正在删除...'
            });
            if (res.ok) {
                $app.showToast({
                    title: res.message,
                })
                this.pageIndex = 1;
                this.loadPageList();
            }
        },
        onCategorySelect(item) {
            console.log(item)
            this.searchParams.postName = item.name;
            this.pageIndex = 1;
            this.loadPageList();
        },
        async loadPageList() {
            // let salary = this.searchList[2] || '~';
            const params = {
                // areaName: this.searchList[0] || '',
                // postName: this.searchParams.postName || "",
                // salaryMin: salary.split("~")[0] || 0,
                // salaryMax: salary.split("~")[1] || 0,
                // details: this.searchList[3] || '',
                // postType: 1,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                companyId: this.userInfo.Id,
            };
            const res = await $app.httpGet({
                path: `/BasePostInfo/GetPageListByCompanyId`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];
                
                newList = newList.map(item => {
                    let Requirements = item.Requirements || "";
                    let Welfare = item.Welfare || "";
                    // 汉字逗号改为英文逗号
                    Requirements = Requirements.replace(/，/g, ',').replace(/、/g, ",");
                    item.RequirementsList = Requirements.split(",");
    
                    Welfare = Welfare.replace(/，/g, ",").replace(/、/g, ",");
                    item.WelfareList = Welfare.split(",");
                    if (item.RequirementsList[0] == "") {
                        item.RequirementsList = [];
                    }
                    if (item.WelfareList[0] == "") {
                        item.WelfareList = [];
                    }
                    item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                    return item;
                });
                
                if (this.pageIndex == 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }
        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.office-manage {
    & .button-group {
        margin-bottom: 0.2rem;
        & .button {
            margin-right: 0.4rem;
            color: #fff;
            padding: 0.16rem 0.4rem;
            border-radius: 0.12rem;
            cursor: pointer;
        }
    }
}
</style>
