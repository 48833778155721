<template>
    <div class="home ">
        <div class="flex-row flex-j-between flex-a-start">
            <category @select="onCategorySelect"/>
            <banner/>
            <information v-if="userInfo.Id"/>
            <login v-else/>
        </div>
        <div class="flex-row flex-j-center flex-a-start">
            <div style="width: 28rem;">
                
                <div style="width: 100%;padding: 0.4rem 0" class="flex-row flex-j-center flex-a-center">
                    <div class="line" style="width: 2.0rem;height: 0.04rem;background-color: #eeecec;"></div>
                    <div style="margin: 0 0.4rem;font-size: 0.5rem;font-weight: 600">热门职位</div>
                    <div class="line" style="width: 2.0rem;height: 0.04rem;background-color: #EEECEC;"></div>
                </div>
                <empty-page v-if="!list||list.length===0" msg="暂无相关职位"/>
                <div class="flex-row flex-j-start flex-a-start" style="flex-wrap: wrap;">
                    <office-list-cell v-for="(item, index) in list" :key="index" :item="item"/>
                    <div
                        style="padding: 0.2rem;cursor: pointer;text-align: center;width: 6.8rem;line-height: 2.8rem;font-size: 0.4rem;"
                         @click="$router.push('/index/office')">点击查看更多职位
                    </div>
                </div>
            </div>
        </div>
        <!--        <home-header/>-->
        <!--        <div class="br"></div>-->
        <!--        <home-entry></home-entry>-->
        <!--        <div class="br"></div>-->
        <!--        <left-tab :tabs="tabs" @change="onTabChange" :active="tabsActive">-->
        <!--            <div v-if="tabsActive==0||tabsActive==1" class="list flex-row flex-j-start flex-a-start">-->
        <!--                <jobfair-list-cell v-for="(item, index) in list" :key="index" :item="item"/>-->
        <!--            </div>-->
        <!--            <div v-if="tabsActive==2" class="list flex-row flex-j-start flex-a-start">-->
        <!--                <office-list-cell v-for="(item, index) in list" :key="index" :item="item"/>-->
        <!--            </div>-->
        <!--        </left-tab>-->
    </div>
</template>

<script>
// home
// import HomeHeader from "@/components/home-header";
// import HomeEntry from "@/components/home-entry";
// import LeftTab from "@/components/left-tab";
// import JobfairListCell from "@/components/jobfair-list-cell";
import OfficeListCell from "@/components/home/office-list-cell";

import Category from "@/components/home/category";
import Banner from "@/components/home/banner";
import Information from "@/components/home/information";
import EmptyPage from "@/components/empty-page";
import Login from "@/components/home/login";
export default {
    name: "home",
    components: {
        Login,
        EmptyPage,
        Information,
        Banner,
        Category,
        OfficeListCell,
        // JobfairListCell,
        // LeftTab,
        // HomeEntry,
        // HomeHeader
    },
    data() {
        return {
            tabsActive: 0,
            tabs: [{
                title: "网络会",
                value: 2
            }, {
                title: "线下会",
                value: 1,
            }, {
                title: "最新职位",
                value: 3
            }],
            list: [],
            pageIndex: 1,
            pageSize: 50,
            searchParams: {
                postName: "",
                // keyword: ""
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    mounted() {
        this.loadOfficeList();
        // 监听头部搜索框搜索事件
        window.$onIndexHeaderSearch = (keyword) => {
            this.searchParams.postName = keyword;
            this.searchParams.keyword = keyword;
            // console.log(keyword)
            // this.pageIndex = 1;
            // this.loadOfficeList();
            this.$router.push(`/index/office?postName=${keyword}`)
        }
    },
    methods: {
        onCategorySelect(item) {
            console.log(item)
            // this.searchParams.postName = item.name;
            // this.pageIndex = 1;
            // this.loadOfficeList();
            this.$router.push(`/index/office?postName=${item.name}`)
        },
        onTabChange(index) {
            console.log(index)
            this.tabsActive = index;
            this.loadData();
        },
        async loadData() {
            if (this.tabsActive == 0 || this.tabsActive == 1) {
                this.loadJobfairList();
            } else {
                this.loadOfficeList();
            }
        },
        async loadJobfairList() {
            // path /BaseJobFair/GetJobFairList
            // 是否是加载更多
            const isLoadMore = this.pageIndex > 1;
            const params = {
                jobFairType: this.tabs[this.tabsActive].value,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                sort: 'JobFairSort',
                sortType: 'asc'
            };
            const res = await $app.httpGet({
                path: `/BaseJobFair/GetJobFairList`,
                params: params,
            });
            
            let oldList = this.list;
            let newList = res.data || [];
            newList = newList.map(item => {
                item.timeText = $app.dateFormatChinese(item.BaseCreateTime);
                
                // 设置状态
                let current = new Date().getTime();
                let start = new Date(item.StartTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                let end = new Date(item.EndTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                if (current < start) {
                    item.status = 0;
                } else if (current >= start && current <= end) {
                    item.status = 1;
                } else if (current > end) {
                    item.status = 2;
                }
                item.startTime = $app.dateFormatChinese(item.StartTime);
                item.endTime = $app.dateFormatChinese(item.EndTime);
                return item;
            })
            if (isLoadMore) {
                oldList = oldList.concat(newList);
            } else {
                oldList = newList;
            }
            this.list = [];
            
            this.list = oldList;
            console.log(this.list);
        },
        async loadOfficeList() {
            // path /BasePostInfo/GetPostList
            // 是否是加载更多
            const isLoadMore = this.pageIndex > 1;
            const params = {
                areaName: '',
                businessName: '',
                salary: 0,
                details: '',
                postName: this.searchParams.postName || "",
                pageIndex: this.pageIndex,
                pageSize: 50,
                postType: 1,
            };
            const res = await $app.httpGet({
                path: `/BasePostInfo/GetPostList`,
                params: params,
            });
            
            let oldList = this.list;
            let newList = res.data || [];
            newList = newList.map(item => {
                let Requirements = item.Requirements || "";
                let Welfare = item.Welfare || "";
                // 汉字逗号改为英文逗号
                Requirements = Requirements.replace(/，/g, ',').replace(/、/g, ",");
                item.RequirementsList = Requirements.split(",");
                
                Welfare = Welfare.replace(/，/g, ",").replace(/、/g, ",");
                item.WelfareList = Welfare.split(",");
                
                if (item.RequirementsList[0] == "") {
                    item.RequirementsList = [];
                }
                if (item.WelfareList[0] == "") {
                    item.WelfareList = [];
                }
                
                item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                
                return item;
            })
            if (isLoadMore) {
                oldList = oldList.concat(newList);
            } else {
                oldList = newList;
            }
            this.list = oldList;
        },
    }
}
</script>

<style scoped lang="less">
.home {
    width: 28rem;
    & .list {
        flex-wrap: wrap;
        background: #fff;
    }
}

</style>
