<template>
    <f-modal
        :title="title"
        :width="800"
        ref="FModal"
        @ok="handleOk"
        @cancel="handleCancel">
        <!--        自定义标签区域开始-->
        <a-form-model ref="ruleForm" :model="params" :rules="rules" v-bind="layout">
            <a-form-model-item has-feedback label="企业名称" prop="CompayName">
                <a-input v-model="params.CompayName" type="text" autocomplete="off"/>
            </a-form-model-item>
    
            <a-form-model-item has-feedback label="行业" prop="BusinessName">
                <a-select v-model="params.BusinessName" placeholder="设置行业">
                    <a-select-option v-for="(item, index) in postNameList" :key="index" :value="item.name">{{
                            item.name }}
                    </a-select-option>
                </a-select>
                <!--                <a-cascader-->
                <!--                    v-model="params.BusinessName"-->
                <!--                    :field-names="postNameField"-->
                <!--                    :options="postNameList"-->
                <!--                    :display-render="postNameDisplayRender"-->
                <!--                    expand-trigger="hover"-->
                <!--                    placeholder="请选择期望岗位"-->
                <!--                    @change="onPostNameChange"-->
                <!--                />-->
            </a-form-model-item>
    
            <a-form-model-item label="所属镇街" prop="AreaName">
                <a-select v-model="params.AreaName" placeholder="设置所属镇街">
                    <a-select-option v-for="(item, index) in areaNameList" :key="index" :value="item.AreaName">{{
                            item.AreaName
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
    
            <a-form-model-item has-feedback label="详细地址" prop="CompayAdd">
                <a-input v-model="params.CompayAdd" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="联系人" prop="MemberName">
                <a-input v-model="params.MemberName" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="联系电话" prop="Phone">
                <a-input v-model="params.Phone" type="number" autocomplete="off"/>
            </a-form-model-item>
            <a-form-model-item has-feedback label="营业执照" prop="">
                <van-uploader class="edit" :file-list="fileList" :after-read="afterRead" max-count="6"
                              :before-delete="onDeleteFileClick" />
            </a-form-model-item>
            
            
        </a-form-model>
        <!--        自定义标签区域结束-->
    </f-modal>
</template>

<script>
// info-edit
import FModal from "@/components/FModal";

export default {
    name: "company-edit",
    components: { FModal},
    data() {
        return {
            //    自定义数据区域开始
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
            },
            fileList: [],
            params: {
                CompayName: "",
                BusinessName: "",
                AreaName: "",
                CompayAdd: "",
                MemberName: "",
                Phone: "",
            },
            rules: {
                CompayName: [{required: true, message: '请输入企业名称', trigger: 'blur'},],
                BusinessName: [{required: true, message: '请选择行业', trigger: 'change'}],
                AreaName: [{required: true, message: '请选择所属镇街', trigger: 'change'}],
                CompayAdd: [{required: true, message: '请输入详细地址', trigger: 'blur'},],
                MemberName: [{required: true, message: '请输入联系人', trigger: 'blur'},],
                Phone: [{required: true, message: '请输入联系电话', trigger: 'blur'},],
            },
            educationList: [],
            areaNameList: [],
            postNameList: [],
            postNameField: {label: 'name', value: 'name', children: 'professionList'},
            //    自定义数据区域结束
            title: '编辑',
            onOk: () => {
            },
            onCancel: () => {
            }
        }
    },
    mounted() {
    },
    methods: {
        //    自定义方法区域开始
        async onSubmit() {
            await this.validate();
            
            const params = this.params;
            params.MemberType = params.MemberType === 3 ? 3 : 2;
            for (let i = 0; i < 6; i++) {
                let key = `CompayLicenseURL${i + 1}`;
                params[key] = null;
            }
            for (let i = 0; i < this.fileList.length; i++) {
                let key = `CompayLicenseURL${i + 1}`;
                params[key] = this.fileList[i].path;
            }
            const updateRes = await $app.httpPost({
                path: '/Member/UpdateMemberInfo',
                params: params,
                loading: '...',
                showError: false
            });
            if (updateRes.ok) {
                $app.showToast({
                    title: '更新成功',
                });
                this.loadUserInfo();
                this.onOk();
                this.$refs['FModal'].done();
            } else {
                this.$refs['FModal'].reset();
            }
            
        },
        async onShow() {
            this.educationList = $app.getEducationOptions();
            this.areaNameList = $app.getStorageSync('areaList');
            this.areaNameList.splice(0, 1);
            // this.postNameList = $app.getStorageSync('businessList');
            this.getBusinessListForCompany();
            this.loadUserInfo();
            
        },
        async getBusinessListForCompany() {
            // let businessList = wx.getStorageSync('businessList') || [];
            // businessList = businessList.map(item => {
            //     return item.DictValue;
            // })
            //businessList: businessList,
            const params = {
                dictType: 'Industry'
            };
            const res = await $app.httpGet({
                path: '/DataDict/GetDataDictDetailList',
                params: params,
                showError: false
            });
        
            if (res.ok) {
                let businessList = res.data || [];
                businessList = businessList.map(item => {
                    return {
                        name: item.DictValue
                    };
                })
                this.postNameList = businessList;
            }
        },
        async loadUserInfo() {
            const params = {
                id: this.params.Id || this.params.id,
            };
            const res = await $app.httpGet({
                path: `/Member/GetUserInfo`,
                params: params,
            });
            if (res.ok) {
                $app.setStorageSync('userInfo', res.data);
                // eslint-disable-next-line no-debugger
                const data = {};
                for (const key in res.data) {
                    if (res.data[key]) {
                        data[key] = res.data[key];
                    }
                    
                }
                this.params = data;
                this.setFileListData(data);
            }
        },
        async afterRead(event) {
            console.log(event)
            const {
                file
            } = event;
            const res = await $app.fileUploadWithFile(2, file);
            if (res.ok) {
                const url = `${$app.staticHost}${res.data}`;
                console.log(url)
                const fileList = this.fileList || [];
                fileList.push({
                    url: url,
                    path: res.data,
                    isImage: true,
                    deletable: true,
                });
                this.fileList = fileList;
            }
        },
        onDeleteFileClick(event) {
            const path = event.path;
            const index = this.fileList.findIndex(item => item.path === path);
            let fileList = this.fileList;
            fileList.splice(index, 1);
            this.fileList = fileList;
            console.log(this.fileList)
        },
        setFileListData(userInfo) {
            let fileList = [];
            for (let i = 0; i < 6; i++) {
                const key = `CompayLicenseURL${i + 1}`;
                if (userInfo[key]) {
                    fileList.push({
                        url: `${$app.staticHost}${userInfo[key]}`,
                        path: userInfo[key],
                        isImage: true,
                        deletable: true,
                    })
                }
            }
            this.fileList = fileList;
        },
        
        //    自定义方法区域结束
        
        validate() {
            return new Promise(resolve => {
                const __this = this;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        console.log('Form 校验错误');
                        __this.$refs['FModal'].reset();
                    }
                });
            })
        },
        handleOk() {
            this.confirmLoading = true;
            this.onSubmit();
        },
        handleCancel() {
            this.visible = false;
            this.onCancel();
        },
        show({params, onOk, onCancel}) {
            this.params = params;
            this.title = params.CompayName ? '编辑' : '企业认证';
            this.$refs['FModal'].show();
            this.onOk = onOk;
            this.onCancel = onCancel;
            this.onShow();
        },
    },
    watch: {
        content() {
            // console.log(this.content)
        }
    }
}
</script>

<style scoped lang="less">
.info-edit {
}
</style>
