<template>
    <div class="login">
        <template v-if="loginType===0">
            <div class="title">登录</div>
            <div class="input-row">
                <span class="label">手机号</span>
                <input v-model="phone" type="text" autocomplete="false"/>
            </div>
            <div class="input-row">
                <span class="label">密码</span>
                <input v-model="password" type="password" autocomplete="false"/>
            </div>
            <div class="input-row">
                <van-button block type="info" @click="onLoginClick">登录</van-button>
                <div class="flex-row flex-j-between flex-a-center" style="width: 100%">
                    <span class="vlogin" @click="loginType=1">验证码登录</span>
                    <span class="vlogin" @click="loginType=2">注册</span>
                </div>
            </div>
        </template>
        <template v-if="loginType===1">
            <div class="title">登录</div>
            <div class="input-row">
                <span class="label">手机号</span>
                <input v-model="params.phone" type="text" autocomplete="false"/>
            </div>
            
            <div class="input-row">
                <span class="label">短信验证码</span>
                <div class="flex-row flex-j-between flex-a-center">
                    <input v-model="params.code" type="text" autocomplete="false"/>
                    <div :class="`send-btn ${isSending ? 'sended' : 'send'}`" @click="onSendClick">{{
                            sendText
                        }}
                    </div>
                </div>
            </div>
            <div class="input-row">
                <van-button type="info" @click="onSubmit" block>登录</van-button>
                <div class="flex-row flex-j-between flex-a-center" style="width: 100%">
                    <span class="vlogin" @click="loginType=0">密码登录</span>
                    <span class="vlogin" @click="loginType=2">注册</span>
                </div>
            </div>
        
        </template>
        <template v-if="loginType===2">
            <div class="flex-col flex-j-start flex-a-center" @click="loginType=1">
                <div class="title" style="font-size: 0.4rem">扫码进入微信小程序注册</div>
                <br>
                <br>
                <img class="xiaochengxuma" src="@/assets/images/xiaochengxuma.jpg" alt="">
                <br>
                <span>点击返回登录</span>
            </div>
            
        </template>
    </div>
</template>

<script>
// login
let timer = null;
export default {
    name: "login",
    data() {
        return {
            // 登录方式
            loginType: 0,
            // 密码登录
            phone: null,
            password: null,
            // 验证码登录
            layout: {
                labelCol: {span: 10},
                wrapperCol: {span: 14},
            },
            params: {
                code: "",
                phone: "",
            },
            rules: {
                code: [{required: true, message: '请输入短信验证码', trigger: 'blur'},],
                phone: [{required: true, message: '请输入手机号', trigger: 'blur'},],
            },
            sendText: "发送验证码",
            timeCount: 60,
            isSending: false,
        }
    },
    mounted() {
    },
    methods: {
        // 密码登录
        checkParams() {
            if ($app.isNullOrEmpty(this.phone)) {
                $app.showToast({
                    title: '请输入手机号',
                    icon: 'none'
                })
                return false;
            }
            if ($app.isNullOrEmpty(this.password)) {
                $app.showToast({
                    title: '请输入密码',
                    icon: 'none'
                })
                return false;
            }
            return {
                phone: this.phone,
                password: this.password
            }
        },
        async onLoginClick() {
            const params = this.checkParams();
            if (!params) return 0;
            const res = await $app.httpGet({
                path: '/Member/LoginByPassword',
                params: params,
                loading: '正在登录'
            });
            if (res.ok) {
                $app.showToast({
                    title: '登录成功'
                })
                
                const data = res.data || {};
                $app.setStorageSync('token', data.Token);
                $app.setStorageSync('userInfo', data.UserInfo);
            }
        },
        
        // 验证码登录
        async onSendClick() {
            if (this.isSending) {
                return
            }
            const params = {
                phone: this.params.phone,
                smsType: 1
            };
            if ($app.isNullOrEmpty(params.phone)) {
                $app.showToast({
                    title: '请输入对方手机号',
                    icon: 'none'
                })
                return false;
            }
            
            const res = await $app.httpGet({
                path: `/Member/SendSMS`,
                params: params,
                loading: '正在发送...'
            });
            // const res = {ok: true, message: '发送成功'}
            if (res.ok) {
                this.isSending = true;
                $app.showToast({
                    title: res.message,
                })
                timer = setInterval(() => {
                    let count = this.timeCount;
                    console.log(count)
                    if (count <= -1) {
                        clearInterval(timer);
                        timer = null;
                        this.isSending = false;
                        this.timeCount = 60;
                        this.sendText = "发送验证码";
                        return 0;
                    }
                    let text = `(${count})s`;
                    this.timeCount = --count;
                    this.sendText = text;
                    
                }, 1000);
            } else {
                this.isSending = false;
                this.timeCount = 60;
                this.sendText = "发送验证码";
            }
            
        },
        //    自定义方法区域开始
        async onSubmit() {
            const validate = this.validate();
            if (!validate) {
                return 0
            }
            const params = this.params;
            const res = await $app.httpGet({
                path: `/Member/LoginBySMS`,
                params: params,
                loading: '正在提交...'
            });
            if (res.ok) {
                $app.showToast({
                    title: '登录成功',
                });
                const data = res.data || {};
                $app.setStorageSync('token', data.Token);
                $app.setStorageSync('userInfo', data.UserInfo);
            } else {
                // this.loginType = 2;
            }
        },
        validate() {
            if ($app.isNullOrEmpty(this.params.phone)) {
                $app.showToast({
                    title: '请输入手机号',
                    icon: 'none'
                })
                return false;
            }
            if ($app.isNullOrEmpty(this.params.code)) {
                $app.showToast({
                    title: '请输入验证码',
                    icon: 'none'
                })
                return false;
            }
            return true;
        },
    }
}
</script>

<style scoped lang="less">
.login {
    width: calc(6rem);
    background: #fff;
    padding: 0.6rem;
    box-sizing: border-box;
    height: 8.0rem;
    overflow: auto;
    position: relative;
    & .title {
        font-size: 0.4rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0.4rem;
    }
    & .input-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.4rem;
        
        & .label {
            color: #666;
        }
        
        & input {
            margin: 0.12rem 0;
            width: 100%;
            box-sizing: border-box;
            padding: 0.12rem;
            border: 0.02rem solid #f0f0f0;
            border-radius: 0.12rem;
            
            &:focus {
                box-shadow: 0 0 0.1rem 0 rgb(163, 236, 202);
                border: rgb(163, 236, 202) solid 0.02rem;
            }
        }
    }
    
    & .vlogin {
        line-height: 0.8rem;
        color: #4eabe8;
        cursor: pointer;
    }
}

.send {
    font-size: .24rem;
    width: 2.40rem;
    height: .80rem;
    background-color: #4eabe8;
    color: #fff;
    border-radius: .12rem;
    text-align: center;
    line-height: .80rem;
    cursor: pointer;
    margin-left: 0.2rem;
}

.send:active {
    opacity: 0.7;
}

.sended {
    font-size: .24rem;
    width: 2.40rem;
    height: .80rem;
    background-color: #fff;
    color: #999;
    border: .02rem solid #999;
    border-radius: .12rem;
    text-align: center;
    line-height: .76rem;
    margin-left: 0.2rem;
}

.xiaochengxuma {
    width: 4.0rem;
    height: 4.0rem;
}
</style>
