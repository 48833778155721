<template>
    <div class="empty-page">
        <van-empty :description="msg" />
    </div>
</template>

<script>
// empty-page
export default {
    name: "empty-page",
    props: ['msg'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {}
}
</script>

<style scoped lang="less">
.empty-page {
}
</style>
