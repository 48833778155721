<template>
    <a-modal
        :title="title"
        :width="width"
        :destroyOnClose="true"
        :visible="visible"
        :confirm-loading="confirmLoading"
        cancelText="取消"
        okText="确定"
        @ok="handleOk"
        @cancel="handleCancel"
    >
        <slot/>
    </a-modal>
</template>

<script>
// FModal
export default {
    name: "FModal",
    props: ['title', 'width'],
    data() {
        return {
            visible: false,
            confirmLoading: false,
        }
    },
    mounted() {
    },
    methods: {
        handleOk() {
            this.confirmLoading = true;
            this.$emit('ok');
        },
        handleCancel() {
            this.visible = false;
            this.$emit('cancel');
        },
        show() {
            this.visible = true;
        },
        done() {
            this.visible = false;
            this.confirmLoading = false;
        },
        reset() {
            this.confirmLoading = false;
        }
    }
}
</script>

<style scoped lang="less">
.FModal {
}
</style>
