//
// FileName app
// ProjectName pubaccount
// ComputerUser gys
// Author 区区电脑
// Email 1573580882@qq.com
// Date 2021/2/16 6:22 下午
// Copyright © 2021 gys. All rights reserved.
import {Toast} from 'vant';
import Axios from 'axios';
import store from '@/store/index'

const host = "https://api.jiuzaipingdu.com";
const staticHost = 'https://api.jiuzaipingdu.com';
const devState = false;
const app = {
    /**
     *  主题色 #f1676d
     *  rgb(78, 171, 232)
     *  #4eabe8
     */
    host: host,
    staticHost: staticHost,
    devState: devState,
    getStorageSync(key) {
        const dataString = localStorage.getItem(key);
        if (dataString) {
            try {
                return JSON.parse(dataString).data;
            } catch (e) {
                console.warn(e);
            }
        }
        return null;
        
    },
    setStorageSync(key, value) {
        let data = {
            data: value
        };
        localStorage.setItem(key, JSON.stringify(data));
        try {
            store.dispatch(key, value);
        } catch (e) {
            console.warn(e)
        }
    },
    showToast({title, icon}) {
        if (icon == 'none') {
            Toast.fail(title)
        } else {
            Toast.success({message: title,forbidClick: true});
        }
    },
    showLoading({title, mask}) {
        Toast.loading({
            message: title,
            forbidClick: mask,
        });
    },
    hideLoading() {
        Toast.clear()
    },
    request({
                url,
                data,
                method = 'GET',
                header,
                success,
                fail
            }) {
        const headers = {
            "Content-Type": 'application/json; charset=utf-8',
            ...header
        };
        Axios({
            url: url,
            method: method,
            data: method == "POST" ? data : {},
            params: method == "GET" ? data : {},
            headers: headers,
        }).then(r => {
            success(r);
        })
        .catch((e) => {
            fail(e)
        });
    },
    uploadRequest({
                url,
                data,
                method = 'GET',
                header,
                success,
                fail
            }) {
        const headers = {
            "Content-Type": "multipart/form-data",
            ...header
        };
        Axios({
            url: url,
            method: method,
            data: method == "POST" ? data : {},
            params: method == "GET" ? data : {},
            headers: headers,
        }).then(r => {
            success(r);
        })
        .catch((e) => {
            fail(e)
        });
    },
    /**
     * 本地获取 token
     */
    getToken() {
        return app.getStorageSync('token');
    },
    /**
     * 判断是否登录
     */
    isLogin() {
        return this.getToken() != null && this.getToken() != undefined && this.getToken() != "" && this.getUserInfo();
    },
    /**
     * 获取本地缓存的用户信息
     */
    getUserInfo() {
        return app.getStorageSync('userInfo');
    },
    /**
     * 生成微信登录 code
     */
    createWeiXinCode() {
        return new Promise(resolve => {
            app.login({
                success: res => {
                    // 发送 res.code 到后台换取 openId, sessionKey, unionId
                    resolve(res.code);
                },
                fail(e) {
                    console.log(e)
                    resolve(false);
                }
            })
        })
    },
    /**
     * get 请求
     * path: 请求路径
     * params: 请求参数,
     * loading: loading 提示, 不传则无loading
     * useToken: 是否使用 token
     * showError: 是否显示错误提示
     */
    httpGet({
                path,
                params,
                loading,
                useToken = true,
                showError = true
            }) {
        params = this.getParams(params);
        if (loading) {
            app.showLoading({
                title: loading,
                mask: true
            })
        }
        const header = {};
        if (useToken) {
            header.Authorization = this.getToken();
        }
        return new Promise(resolve => {
            app.request({
                url: `${host}${path}`,
                data: params,
                method: 'GET',
                header: header,
                success(response) {
                    if (loading) {
                        app.hideLoading()
                    }
                    let ok = response.data && response.data.Tag == 1;
                    if (!ok && showError) {
                        app.showToast({
                            title: response.data.Message,
                            icon: 'none'
                        })
                    }
                    resolve({
                        ok: ok,
                        message: response.data.Message,
                        data: response.data.Result
                    })
                },
                fail() {
                    if (loading) {
                        app.hideLoading()
                    }
                    if (showError) {
                        app.showToast({
                            title: '网络错误',
                            icon: 'none'
                        })
                    }
                    resolve({
                        ok: false,
                        message: '网络错误',
                        data: null
                    })
                }
            })
        })
    },
    /**
     * 解析请求参数
     * 把参数中带有全部的值都改为 ""
     * @param {*} params
     */
    getParams(params) {
        try {
            for (let key in params) {
                if (typeof params[key] == "string") {
                    if (params[key].indexOf("全部") > -1 || params[key].indexOf("不限") > -1) {
                        params[key] = ""
                    }
                }
            }
        } catch (e) {
            console.warn(e)
        }
        return params;
    },
    /**
     * post 请求
     * path: 请求路径
     * params: 请求参数,
     * loading: loading 提示, 不传则无loading
     * useToken: 是否使用 token
     * showError: 是否显示错误提示
     */
    httpPost({
                 path,
                 params,
                 loading,
                 useToken = true,
                 showError = true
             }) {
        params = this.getParams(params);
        // eslint-disable-next-line no-debugger
        if (loading) {
            app.showLoading({
                title: loading,
                mask: true
            })
        }
        const header = {};
        if (useToken) {
            header.Authorization = this.getToken();
        }
        return new Promise(resolve => {
            app.request({
                url: `${host}${path}`,
                data: params,
                method: 'POST',
                header: header,
                success(response) {
                    if (loading) {
                        app.hideLoading()
                    }
                    let ok = response.data && response.data.Tag == 1;
                    if (!ok && showError) {
                        app.showToast({
                            title: response.data.Message,
                            icon: 'none'
                        })
                    }
                    resolve({
                        ok: ok,
                        message: response.data.Message,
                        data: response.data.Result
                    })
                },
                fail() {
                    if (loading) {
                        app.hideLoading()
                    }
                    if (showError) {
                        app.showToast({
                            title: '网络错误',
                            icon: 'none'
                        })
                    }
                    resolve({
                        ok: false,
                        message: '网络错误',
                        data: null
                    })
                }
            })
        })
    },
    /**
     * 判断是否为null
     * @param {*} value
     */
    isNullOrEmpty(value) {
        return value == null || value == undefined || value == '' || value == 'null';
    },
    /**
     * 转换为汉字时间
     * @param {*} dateString
     * @param {*} hms
     */
    dateFormatChinese(dateString = '', hms = false /*是否携带时分秒*/) {
        let date = new Date(dateString.replace(/-/g, '/').replace(/T/g, ' '));
        let year = date.getFullYear() + '';
        let month = date.getMonth() + 1 + '';
        let day = date.getDate() + '';
        let hour = date.getHours() + '';
        let minute = date.getMinutes() + '';
        let second = date.getSeconds() + '';
        
        if (hms) {
            hour = hour.length == 1 ? `0${hour}` : hour;
            minute = minute.length == 1 ? `0${minute}` : minute;
            second = second.length == 1 ? `0${second}` : second;
            return `${year}年${month}月${day}日 ${hour}:${minute}:${second}`;
        }
        // month = month.length == 1 ? `0${month}` : month;
        // day = day.length == 1 ? `0${day}` : day;
        return `${year}年${month}月${day}日`;
    },
    getEducationOptions() {
        return ['不限', '初中', '高中', '专科', '本科', '研究生', '博士']
    },
    /**
     * 获取镇街
     * 字典数据
     */
    getAreaList() {
        // /Area/GetAreaList
        const params = {
            // parentCode: 0
        };
        this.httpGet({
            path: '/Area/GetAreaList',
            params: params,
            useToken: false,
            showError: false
        }).then(res => {
            if (res.ok) {
                let data = res.data || [];
                data.unshift({
                    AreaName: '全部地点'
                });
                app.setStorageSync('areaList', data);
            }
        })
    },
    /**
     * 获取行业
     * 字典数据
     */
    getBusinessList() {
        // /DataDict/GetDataDictDetailList
        const params = {
            // DictType: 'businessType'
        };
        this.httpGet({
            path: '/BaseProfession/GetProfessionList',
            params: params,
            useToken: false,
            showError: false
        }).then(res => {
            if (res.ok) {
                let data = res.data || [];
                data.unshift({
                    name: '全部岗位',
                    DictValue: '全部岗位',
                    professionList: [{name: '全部'}]
                });
                data = data.map(item => {
                    // 更换过接口，代码中有大量的地方使用的是原接口中的数据字段名 DictValue
                    // 兼容原接口中的字段名
                    item.DictValue = item.name;
                    item.professionList = item.professionList || [];
                    // item.professionList.unshift({
                    //     name: '全部岗位',
                    //     DictValue: '全部岗位'
                    // })
                    return item;
                });
                
                app.setStorageSync('businessList', data);
            }
        })
    },
    /*fileUpload(fileModule = 2, file) {
        // 1 头像 2 图片 3 文件
        return new Promise(resolve => {
            let __this = this;
            if (file) {
                app.uploadFile({
                    url: `${host}/File/UploadFile?fileModule=${fileModule}`, //仅为示例，非真实的接口地址
                    filePath: file.url,
                    name: 'file',
                    header: {
                        Authorization: __this.getToken()
                    },
                    formData: {
                        // 1 头像 2 图片 3 文件
                        // 'fileModule': 2
                    },
                    success(response) {
                        const data = response.data
                        console.log(response);
                        let res = {};
                        try {
                            res = JSON.parse(response.data);
                        } catch (e) {
                            console.warn(e);
                        }
                        if (res.Tag == 1) {
                            resolve({
                                ok: true,
                                data: res.Result,
                                message: ""
                            })
                            
                        } else {
                            resolve({
                                ok: false,
                                data: null,
                                message: res.Message
                            })
                        }
                        
                        //do something
                    }
                })
            } else {
                app.chooseImage({
                    count: 1,
                    success(res) {
                        const tempFilePaths = res.tempFilePaths
                        app.uploadFile({
                            url: `${host}/File/UploadFile?fileModule=${fileModule}`, //仅为示例，非真实的接口地址
                            filePath: tempFilePaths[0],
                            name: 'file',
                            header: {
                                Authorization: __this.getToken()
                            },
                            formData: {
                                // 1 头像 2 图片 3 文件
                                // 'fileModule': 2
                            },
                            success(response) {
                                const data = response.data
                                console.log(response);
                                let res = {};
                                try {
                                    res = JSON.parse(response.data);
                                } catch (e) {
                                    console.warn(e);
                                }
                                if (res.Tag == 1) {
                                    resolve({
                                        ok: true,
                                        data: res.Result,
                                        message: ""
                                    })
                                    
                                } else {
                                    resolve({
                                        ok: false,
                                        data: null,
                                        message: res.Message
                                    })
                                }
                                
                                //do something
                            }
                        })
                    }
                })
            }
            
        })
    },*/
    userEvents: [],
    addUserUpdateEvent(event) {
        this.userEvents.push(event);
    },
    userUpdate() {
        this.userEvents.map(item => {
            if (item) {
                item();
            }
        })
    },
    fileUpload (fileModule) {
        let tempId = parseInt(String(Math.random() * 1000000000));
        let input = document.createElement("input");
        let form = document.createElement("form");
        input.type = "file";
        input.name = "file";
        input.id = String(tempId);
        form.style.display = "none";
        form.id = "form_" + tempId;
        form.enctype = "multipart/form-data";
        // form.enctype = "application/json";
        form.appendChild(input);
        form.style.width = "1px";
        form.style.height = "1px";
        form.style.display = "none";
        document.body.appendChild(form);
    
    
        return new Promise((resolve) => {
            input.onchange = () => {
                let result = {
                
                };
                let max = 2 * 1024 * 1024; // 字节
                if (input.files[0].size >= max) {
                    app.showToast({
                        title: '文件过大，请选择 2M 以内图片',
                        icon: 'none'
                    })
                    result.message = '文件过大，请选择 2M 以内图片';
                    result.ok = false;
                    return resolve(result);
                }
            
                let formData = new FormData(form);
            
                app.showLoading({
                    title: '正在上传',
                    mask: true
                })
                app.uploadRequest({
                    method: 'POST',
                    url: `${app.host}/File/UploadFile?fileModule=${fileModule}`,
                    data: formData,
                    headers: {
                        token: app.getToken(),
                    },
                    success(response) {
                        app.hideLoading();
                        let res = response.data;
                    
                        // eslint-disable-next-line no-debugger
                        if (res.Tag == 1) {
                            resolve({
                                ok: true,
                                data: res.Result,
                                message: ""
                            })
                        } else {
                            resolve({
                                ok: false,
                                data: null,
                                message: res.Message
                            })
                            app.showToast({
                                title: res.Message,
                                icon: 'none'
                            })
                        }
                        form.remove();
                    },
                    fail(e) {
                        form.remove();
                        app.showToast({
                            title: e,
                            icon: 'none'
                        })
                    }
                })
            };
            input.click();
        });
    },
    fileUploadWithFile(fileModule, file) {
        const formData = new FormData();
        formData.append('file', file);
        return new Promise(resolve => {
            app.uploadRequest({
                method: 'POST',
                url: `${app.host}/File/UploadFile?fileModule=${fileModule}`,
                data: formData,
                headers: {
                    token: app.getToken(),
                },
                success(response) {
                    app.hideLoading();
                    let res = response.data;
            
                    // eslint-disable-next-line no-debugger
                    if (res.Tag == 1) {
                        resolve({
                            ok: true,
                            data: res.Result,
                            message: ""
                        })
                    } else {
                        resolve({
                            ok: false,
                            data: null,
                            message: res.Message
                        })
                        app.showToast({
                            title: res.Message,
                            icon: 'none'
                        })
                    }
                },
                fail(e) {
                    app.showToast({
                        title: e,
                        icon: 'none'
                    })
                }
            })
        })
    }
};

window.$app = app;
export default app;
