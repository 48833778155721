<template>
    <div class="user flex-col flex-j-start flex-a-center">
        <div class="user-center flex-col flex-j-start flex-a-center">
            <span class="home" @click="onBackHome">
                <a class="iconfont icon-iconfontzhizuobiaozhun023101" style="color: #2c3e50"></a>
                首页
            </span>
            <div class="avatar">
                <img :src="userInfo.HeadImage" alt=""/>
                <span @click="onUploadClick" class="icon iconfont icon-shangchuan"></span>
            </div>
            <div class="info">
                <div class="flex-row flex-j-between flex-a-center">
                    <span class="flex-1">昵称：{{userInfo.MemberName}}
                        <a v-if="userInfo.Sex==1" class="iconfont icon-nanxingmale209" style="color: #39a9ed"></a>
                        <a v-if="userInfo.Sex==2" class="iconfont icon-nv" style="color: #f1676d"></a>
                    </span>
                    <span class="flex-1">手机号：{{userInfo.Phone || '--'}}</span>
                    <span class="flex-1">邮箱：{{userInfo.Email || '--'}}</span>
                </div>
                <br>
                <div class="flex-row flex-j-between flex-a-center">
                    <span class="flex-1">学历：{{userInfo.Education || '--'}}</span>
                    <span class="flex-1">年龄：{{userInfo.Age || '--'}}</span>
                    <span class="flex-1">婚否：{{userInfo.Marriage | Marriage}}</span>
                </div>
                <br>
                
            </div>
        </div>
        <left-tab :tabs="tabs" @change="onTabChange" :active="tabsActive">
            <router-view/>
        </left-tab>
    </div>
</template>

<script>
// user
import LeftTab from "@/components/left-tab";
export default {
    name: "user",
    components: {
        LeftTab
    },
    filters: {
        Marriage: function (value) {
            console.log(value)
            if (value == 0) {
                return '保密';
            } else if (value == 1) {
                return '未婚';
            } else if (value == 2) {
                return '已婚'
            }
        }
    },
    data() {
        return {
            tabsActive: 0,
            tabs: [{
                title: "个人信息",
                value: 1,
                name: 'info'
            }, {
                title: "零工之家列表",
                value: 2,
                name: 'work-manage'
            },{
                title: '设置登录密码',
                name: 'set-password'
            }, {
                title: "职位收藏夹",
                value: 1000,
            }],
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo || {};
        },
        routerName() {
            return this.$route.name;
        }
    },
    mounted() {
        this.tabsActive = this.tabs.findIndex(item => item.name == this.routerName);
    },
    activated() {
        this.tabsActive = this.tabs.findIndex(item => item.name == this.routerName);
    },
    methods: {
        onTabChange(index) {
            console.log(index)
            this.tabsActive = index;
            const item = this.tabs[index];
            if (item.name) {
                this.$router.push(`/user/${item.name}`)
            }
            if (item.value === 1000) {
                $app.showToast({
                    title: '敬请期待',
                })
            }
            // this.pageIndex = 1;
            // this.list = [];
            // this.loadPageList();
        },
        onBackHome() {
            this.$router.replace('/')
        },
        async onUploadClick() {
            const res = await $app.fileUpload(2);
            const params = {
                ...this.userInfo,
            };
            params.HeadImage = res.data;
            const updaterRes = await $app.httpPost({
                path: '/Member/UpdateMemberInfo',
                params: params,
                loading: '...',
            });
            if (updaterRes.ok) {
                const r = await $app.httpGet({
                    path: `/Member/GetUserInfo`,
                    params: {id: params.Id},
                });
                if (r.ok) {
                    $app.setStorageSync('userInfo', r.data);
                }
            }
        }
    }
}
</script>

<style scoped lang="less">
.user {
    & .user-center {
        width: calc(22rem);
        background-color: #68dbce;
        padding: 0.4rem;
        border-radius: 0.12rem;
        margin: 0.2rem 0;
        box-sizing: border-box;
        box-shadow: 0 0 0.2rem 0.1rem rgba(104, 219, 206, 0.2);
        position: relative;
        & .home {
            position: absolute;
            left: 0.4rem;
            top: 0.4rem;
            color: #2c3e50;
            cursor: pointer;
            &:active {
                opacity: 0.9;
            }
        }
        & .avatar {
            width: 3.0rem;
            height: 3.0rem;
            border-radius: 0.2rem;
            border: 0.04rem solid #fff;
            position: relative;
            overflow: hidden;
            & img {
                width: 100%;
                height: 100%;
                border-radius: 0.2rem;
            }
            & .icon {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                text-align: center;
                line-height: 3.0rem;
                font-size: 0.8rem;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1;
                opacity: 0;
                transition: all 0.3s ease;
            }
            &:hover {
                & .icon {
                    opacity: 1;
                }
            }
        }
        & .info {
            margin-top: 0.4rem;
            width: 17.6rem;
            color: #fff;
            text-align: left;
            font-weight: 600;
        }
    }
}
</style>
