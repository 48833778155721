<template>
    <div class="left-tab flex-row flex-j-between flex-a-start">
        <div class="left">
            <div v-for="(item, index) in tabs"
                 :class="`tab tap ${active == index ? 'active' : ''}`"
                 :key="index" @click="onTabClick(index)">{{
                    item
                        .title
                }}
            </div>
        </div>
        <div class="right">
            <slot></slot>
        </div>
    </div>
</template>

<script>
// left-tab
export default {
    name: "left-tab",
    props: ['tabs', 'active'],
    data() {
        return {
        }
    },
    created() {
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        onTabClick(index) {
            this.$emit('change', index)
        }
    }
}
</script>

<style scoped lang="less">
.left-tab {
    width: calc(22rem);
    margin: 0 auto;
    & .left {
        width: 4.4rem;
        background: #fff;
        margin-right: 0.2rem;
        & .tab {
            padding: 0.2rem;
            cursor: default;
            color: #666666;
            position: relative;
            font-size: 0.36rem;
            &::after {
                content: " ";
                position: absolute;
                width: 0.20rem;
                height: 0.40rem;
                right: 0.04rem;
                opacity: 0;
                top: calc(50% - 0.20rem);
                background: linear-gradient(to right, #1989fa, rgba(78, 171, 232, 0.8));
                -webkit-clip-path: polygon(0 0, 0 100%, 100% 50%);
                clip-path: polygon(0 0, 0 100%, 100% 50%);
                transition: all 0.3s ease;
                z-index: -1;
            }
            &:hover {
                color: #1989fa;
            }
        }
        & .active {
            color: #fff;
            background-image: linear-gradient(to right, #1989fa, rgba(78, 171, 232, 0.8));
            &::after {
                right: -0.20rem;
                opacity: 1;
                z-index: 1;
            }
            &:hover {
                color: #fff;
            }
        }
    }
    & .right {
        width: 17.6rem;
        box-sizing: border-box;
    }
}
</style>
