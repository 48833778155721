<template>
    <f-modal
        :title="title"
        :width="800"
        ref="FModal"
        @ok="handleOk"
        @cancel="handleCancel">
        <!--        自定义标签区域开始-->
        <a-form-model ref="ruleForm" :model="params" :rules="rules" v-bind="layout">
            <a-form-model-item has-feedback label="姓名" prop="MemberName">
                <a-input v-model="params.MemberName" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item label="学历" prop="Education">
                <a-select v-model="params.Education" placeholder="设置学历">
                    <a-select-option v-for="(item, index) in educationList" :key="index" :value="item">{{ item }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="期望岗位" prop="PostName">
                <a-cascader
                    v-model="params.PostName"
                    :field-names="postNameField"
                    :options="postNameList"
                    :display-render="postNameDisplayRender"
                    expand-trigger="hover"
                    placeholder="请选择期望岗位"
                    @change="onPostNameChange"
                />
            </a-form-model-item>
            
            <a-form-model-item label="性别" prop="Sex">
                <a-radio-group v-model="params.Sex">
<!--                    <a-radio :value="0">未知</a-radio>-->
                    <a-radio :value="1">男</a-radio>
                    <a-radio :value="2">女</a-radio>
                </a-radio-group>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="年龄" prop="Age">
                <a-input v-model="params.Age" type="number" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="手机号" prop="Phone">
                <a-input v-model="params.Phone" type="number" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item label="期望工作所在地" prop="AreaName">
                <a-select v-model="params.AreaName" placeholder="设置期望工作所在地">
                    <a-select-option v-for="(item, index) in areaNameList" :key="index" :value="item.AreaName">{{
                            item.AreaName
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="个人简介" prop="Details">
                
                <FEditor v-model="params.Details"/>
                <!--                <a-select v-model="params.Details" placeholder="设置学历">-->
                <!--                    <a-select-option v-for="(item, index) in areaNameList" :key="index" :value="item.AreaName">{{-->
                <!--                            item.AreaName }}-->
                <!--                    </a-select-option>-->
                <!--                </a-select>-->
            </a-form-model-item>
        
        </a-form-model>
        <!--        自定义标签区域结束-->
    </f-modal>
</template>

<script>
// info-edit
import FModal from "@/components/FModal";

import FEditor from "@/components/FEditor";

export default {
    name: "info-edit",
    components: {FEditor, FModal},
    data() {
        return {
            //    自定义数据区域开始
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
            },
            params: {
                Age: 0,
                AreaName: null,
                // BirthPlace: "",
                BusinessName: null,
                // CompayAdd: "青岛市北区",
                // CompayCode: "",
                // CompayLicenseURL1: "/Resource/News/2021/02/19/d6dad614f62f4cd2bb1e43bdd14780b4.jpg",
                // CompayLicenseURL2: "",
                // CompayLicenseURL3: "",
                // CompayLicenseURL4: "",
                // CompayLicenseURL5: "",
                // CompayLicenseURL6: "",
                // CompayName: "666航天",
                // CurrentPlace: "",
                Details: null,
                Education: null,
                // Email: "",
                HeadImage:
                    null,
                Id: "278630580105519104",
                // IdentityCard: "",
                // IsShow: false,
                Marriage: null,
                MemberName: null,
                // MemberType: 1,
                Phone: null,
                PostName: null,
                Sex: 0,
            },
            rules: {
                MemberName: [{required: true, message: '请输入姓名', trigger: 'blur'},],
                Education: [{required: true, message: '请选择学历', trigger: 'change'}],
                PostName: [{required: true, message: '请设置期望岗位', trigger: 'change'},],
                Sex: [{required: true, message: '请设置性别', trigger: 'change'}],
                Age: [{required: true, message: '请输入年龄', trigger: 'blur'},],
                Phone: [{required: true, message: '请输入手机号', trigger: 'blur'},],
                AreaName: [{required: true, message: '请选择期望工作所在地', trigger: 'change'}],
            },
            educationList: [],
            areaNameList: [],
            postNameList: [],
            postNameField: {label: 'name', value: 'name', children: 'professionList'},
            //    自定义数据区域结束
            title: '编辑',
            onOk: () => {
            },
            onCancel: () => {
            }
        }
    },
    mounted() {
    },
    methods: {
        //    自定义方法区域开始
        async onSubmit() {
            await this.validate();
            
            const params = this.params;
            params.PostName = params.PostName[1];

            const updateRes = await $app.httpPost({
                path: '/Member/UpdateMemberInfo',
                params: params,
                loading: '...',
                showError: false
            });
            if (updateRes.ok) {
                $app.showToast({
                    title: '更新成功',
                });
                this.loadUserInfo();
                this.onOk();
                this.$refs['FModal'].done();
            } else {
                this.$refs['FModal'].reset();
            }
            
        },
        async onShow() {
            this.educationList = $app.getEducationOptions();
            this.educationList.splice(0, 1);
            this.areaNameList = $app.getStorageSync('areaList');
            this.postNameList = $app.getStorageSync('businessList');
            this.loadUserInfo();
            
        },
        async loadUserInfo() {
            const params = {
                id: this.params.Id || this.params.id,
            };
            const res = await $app.httpGet({
                path: `/Member/GetUserInfo`,
                params: params,
            });
            if (res.ok) {
                $app.setStorageSync('userInfo', res.data);
                // eslint-disable-next-line no-debugger
                const data = {};
                for (const key in res.data) {
                    if (res.data[key]) {
                        data[key] = res.data[key];
                    }
                    
                }
                data.PostName = [data.BusinessName, data.PostName];
                
                this.params = data;
            }
        },
        postNameDisplayRender({labels}) {
            return labels[labels.length - 1];
        },
        onPostNameChange(value) {
            console.log(value);
            this.params.BusinessName = value[0] || ""
        },
        
        //    自定义方法区域结束
        
        validate() {
            return new Promise(resolve => {
                const __this = this;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        console.log('Form 校验错误');
                        __this.$refs['FModal'].reset();
                    }
                });
            })
        },
        handleOk() {
            this.confirmLoading = true;
            this.onSubmit();
        },
        handleCancel() {
            this.visible = false;
            this.onCancel();
        },
        show({params, onOk, onCancel}) {
            this.params = params;
            this.title = params.id || params.Id ? '编辑' : '添加';
            this.$refs['FModal'].show();
            this.onOk = onOk;
            this.onCancel = onCancel;
            this.onShow();
        },
    },
    watch: {
        content() {
            // console.log(this.content)
        }
    }
}
</script>

<style scoped lang="less">
.info-edit {
}
</style>
