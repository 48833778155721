<template>
    <f-modal
        :title="title"
        :width="800"
        ref="FModal"
        @ok="handleOk"
        @cancel="handleCancel">
        <!--        自定义标签区域开始-->
        <a-form-model ref="ruleForm" :model="params" :rules="rules" v-bind="layout">
            
            <a-form-model-item has-feedback label="岗位名称" prop="PostName">
                <a-cascader
                    v-model="params.PostName"
                    :field-names="postNameField"
                    :options="postNameList"
                    :display-render="postNameDisplayRender"
                    expand-trigger="hover"
                    placeholder="请选择岗位名称"
                    @change="onPostNameChange"
                />
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="招聘人数" prop="NeedNum">
                <a-input v-model="params.NeedNum" type="number" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="其他要求" prop="Requirements">
                <a-input v-model="params.Requirements" type="text" autocomplete="off" placeholder="其他要求，多个要求用逗号隔开"/>
            </a-form-model-item>
            
            <a-form-model-item label="学历要求" prop="Education">
                <a-select v-model="params.Education" placeholder="设置学历">
                    <a-select-option v-for="(item, index) in educationList" :key="index" :value="item">{{ item }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="薪资范围 高" prop="SalaryMax">
                <span style="color: #f1676d;padding:0 .20rem;">薪资面议请将薪资范围的高和低设置为 0</span>
                
                <a-input v-model="params.SalaryMax" type="number" autocomplete="off" placeholder="高"/>
            </a-form-model-item>
            <a-form-model-item has-feedback label="薪资范围 低" prop="SalaryMin">
                <a-input v-model="params.SalaryMin" type="number" autocomplete="off" placeholder="低"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="公司福利" prop="Welfare">
                <a-input v-model="params.Welfare" type="text" autocomplete="off" placeholder="公司福利，多个福利用逗号隔开"/>
            </a-form-model-item>
            
            <a-form-model-item label="职位描述" prop="Details">
                <FEditor v-model="params.Details" :height="300"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="联系人" prop="LinkMan">
                <a-input v-model="params.LinkMan" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="联系方式" prop="LinkPhone">
                <a-input v-model="params.LinkPhone" type="number" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="公司地址" prop="CompanyAdd">
                <a-input v-model="params.CompanyAdd" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item label="所在镇街" prop="AreaName">
                <a-select :value="params.AreaName" @change="onSelect" placeholder="设置所在镇街">
                    <a-select-option v-for="(item, i) in areaNameList" :key="`__${i}`" :value="item.AreaName">{{
                            item.AreaName
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
        <!--        自定义标签区域结束-->
    </f-modal>
</template>

<script>
// info-edit
import FModal from "@/components/FModal";

import FEditor from "@/components/FEditor";

export default {
    name: "office-edit",
    components: {FEditor, FModal},
    data() {
        return {
            //    自定义数据区域开始
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
            },
            params: {
                PostName: "",
                NeedNum: "",
                Requirements: "",
                Education: "",
                LinkMan: "",
                LinkPhone: "",
                AreaName: "",
                CompanyAdd: "",
                SalaryMax: "",
                Welfare: "",
                CompanyName: "",
                SalaryMin: ""
            },
            rules: {
                PostName: [{required: true, message: '请输入岗位名称', trigger: 'blur'},],
                NeedNum: [{required: true, message: '请输入招聘人数', trigger: 'blur'},],
                Requirements: [{required: true, message: '请输入其他要求', trigger: 'blur'},],
                Education: [{required: true, message: '请设置学历要求', trigger: 'change'}],
                LinkMan: [{required: true, message: '请输入联系人', trigger: 'blur'},],
                LinkPhone: [{required: true, message: '请输入联系方式', trigger: 'blur'},],
                AreaName: [{required: true, message: '请设置所在镇街', trigger: 'change'}],
                CompanyAdd: [{required: true, message: '请输入公司地址', trigger: 'change'},],
                SalaryMin: [{required: true, message: '请输入薪资', trigger: 'change'},],
                SalaryMax: [{required: true, message: '请输入薪资', trigger: 'change'},],
            },
            educationList: [],
            areaNameList: [],
            postNameList: [],
            postNameField: {label: 'name', value: 'name', children: 'professionList'},
            //    自定义数据区域结束
            title: '编辑',
            onOk: () => {
            },
            onCancel: () => {
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    mounted() {
    },
    methods: {
        //    自定义方法区域开始
        async onSubmit() {
            await this.validate();
            
            const params = this.params;
            params.BusinessName = params.PostName[0];
            params.PostName = params.PostName[1];
            params.PostType = 1;
            
            const url = params.Id || params.id ? '/BasePostInfo/UpdatePostInfo' : '/BasePostInfo/CreatePostInfo';
            const res = await $app.httpPost({
                path: url,
                params: params,
                loading: '正在提交',
            });
            if (res.ok) {
                $app.showToast({
                    title: params.Id || params.id ? '更新成功' : '创建成功',
                });
                this.onOk();
                this.$refs['FModal'].done();
            } else {
                this.$refs['FModal'].reset();
            }
            
        },
        async onShow() {
            this.educationList = $app.getEducationOptions();
            this.areaNameList = $app.getStorageSync('areaList');
            this.areaNameList.splice(0, 1);
            this.postNameList = $app.getStorageSync('businessList');
            if (!this.params.Id) {
                const params = this.params;
                params.CompanyName = this.userInfo.CompayName;
                params.CompanyAdd = this.userInfo.CompayAdd;
                params.AreaName = this.userInfo.AreaName;
                params.LinkMan = this.userInfo.MemberName;
                params.LinkPhone = this.userInfo.Phone;
                params.relationId = this.userInfo.Id;
                this.params = params;
            }
            // this.loadInfo();
            
        },
        // async loadInfo() {
        //
        // },
        postNameDisplayRender({labels}) {
            return labels[labels.length - 1];
        },
        onPostNameChange(value) {
            console.log(value);
            this.params.BusinessName = value[0] || ""
        },
        // 强制设置下拉框数据
        onSelect(value) {
            console.log(value)
            const params = this.params;
            params.AreaName = value;
            this.params = params;
            this.$forceUpdate();
        },
        
        //    自定义方法区域结束
        
        validate() {
            return new Promise(resolve => {
                const __this = this;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        console.log('Form 校验错误');
                        __this.$refs['FModal'].reset();
                    }
                });
            })
        },
        handleOk() {
            this.confirmLoading = true;
            this.onSubmit();
        },
        handleCancel() {
            this.visible = false;
            this.onCancel();
        },
        show({params, onOk, onCancel}) {
            const data = {};
            for (const key in params) {
                if (params[key]) {
                    data[key] = params[key]
                }
            }
            data.PostName = null;
            this.params = data;
            
            this.title = params.id || params.Id ? '编辑' : '添加';
            this.$refs['FModal'].show();
            this.onOk = onOk;
            this.onCancel = onCancel;
            this.onShow();
        },
    }
}
</script>

<style scoped lang="less">
.info-edit {
}
</style>
