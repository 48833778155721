<template>
    <div class="lazy-img" @click="$emit('click')">
        <div v-if="srcSuccess==0" class="image-placeholder" style="width: 100%; height: 100%">加载中...</div>
        <img v-if="srcSuccess==1" :src="hostSrc" style="width: 100%; height: 100%" @error="onLoadError"/>
        <div v-if="srcSuccess==2" class="image-placeholder" style="width: 100%; height: 100%">
            <img src="@/assets/images/load_err.png" style="width: 1.2rem; height: 1.0rem"/>
        </div>
        <div v-if="srcSuccess!=0&&srcSuccess!=1&&srcSuccess!=2" class="image-placeholder"
             style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
// lazy-img
export default {
    name: "lazy-img",
    props: {
        src: {
            type: String
        },
    },
    data() {
        return {
            // 0 加载中
            // 1 加载成功
            // 2 加载失败
            srcSuccess: 0,
            hostSrc: ''
        }
    },
    mounted() {
        this.srcSuccess = 1;
        this.hostSrc = `${$app.staticHost}${this.src}`
    },
    methods: {
        onLoadError() {
            this.srcSuccess = 2;
        }
    },
    watch: {
        src() {
            this.srcSuccess = 1;
            this.hostSrc = `${$app.staticHost}${this.src}`
        }
    }
}
</script>

<style scoped lang="less">
.lazy-img {
    width: 100%;
    height: 100%;
    
    & .image-placeholder {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
</style>
