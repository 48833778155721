<template>
    <div id="app flex-col">
        <keep-alive>
            <router-view/>
        </keep-alive>
        <br>
        <div class="copyright">
            <div style="margin:0 auto; padding:20px 0;">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo"
                   style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
                    src="@/assets/icons/WechatIMG221.png"
                    style="float:left;"/>
                    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">鲁公网安备
                        37028302000793号</p></a>
                <span style="padding: 0 20px"></span>
                <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
                   style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">鲁ICP备2021004417号</p></a>
            </div>
            <p style="color: #939393">本网站所涉内容为用户发表并上传，相应的法律责任由用户自行承担；如发现违法、不良信息和侵权问题，请与本网站联系！联系邮箱：pdjyrc@163.com</p>
            <p style="color: #939393;cursor: pointer;" @click="$router.push('/zhizhao')">青岛平度汇英人才市场管理有限公司  营业执照
                人力资源服务许可证</p>
        </div>
        <div :style="`opacity: ${showScrollTop ? '1' : '0'}; transform: scale(${showScrollTop ? '1' : '0'})`"
             class="scrollTop iconfont icon-shangjiantou"
             @click="onScrollTopClick" title="回到顶部"></div>
    
    </div>
</template>

<script>
// import AppLeft from "@/components/app-left";
// import AppRight from "@/components/app-right";

export default {
    name: "App",
    components: {
        // AppRight, AppLeft
    },
    data() {
        return {
            showScrollTop: false
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo || {};
        }
    },
    created() {
        this.loadMyInfo();
        window.onscroll = () => {
            let scrollTop = window.document.documentElement.scrollTop;
            if (scrollTop > 500) {
                this.showScrollTop = true;
            } else {
                this.showScrollTop = false;
            }
        }
    },
    methods: {
        async loadMyInfo() {
            if (this.userInfo.Id) {
                const params = {
                    id: this.userInfo.Id
                };
                const res = await $app.httpGet({
                    path: `/Member/GetUserInfo`,
                    params: params,
                });
                if (res.ok) {
                    $app.setStorageSync('userInfo', res.data);
                }
            }
        },
        onScrollTopClick() {
            window.document.documentElement.scrollTop = 0
        }
    }
};
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 0.32rem;
    /*font-size: 16px;*/
    min-height: 100vh;
    width: 100vw;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

html, body {
    background-color: #f5f5f5 !important;
}

//.router {
//    width: 100vw;
//}

img {
    border: none;
}

.Details {
    white-space: pre-wrap;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.editor {
    width: 100%;
    box-sizing: border-box;
    line-height: 0.56rem;
    height: 8.00rem;
    text-align: left;
    font-size: 0.26rem;
    background-color: #f5f5f5;
    border-radius: 0.12rem;
    border: none;
    padding: 0.08rem 0.2rem;
}

.scrollTop {
    position: fixed;
    bottom: 2.0rem;
    z-index: 999;
    right: calc(50% - 14rem);
    background-color: #68dbce;
    color: #fff;
    font-size: 0.6rem;
    width: 0.8rem;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    cursor: pointer;
    box-shadow: 0 0 0.1rem 0 rgba(104, 219, 206, 0.1);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    
    &:hover {
        box-shadow: 0 0 0.2rem 0.1rem rgba(104, 219, 206, 0.2);
    }
    
    &:active {
        opacity: 0.8;
    }
}

.bottom-placeholder {
    height: 1.4rem;
    width: 100%;
}

.copyright {
    padding: 0.2rem 0;
    color: #f0f0f0;
    background-color: #2c3e50;
    //position: fixed;
    //bottom: 0;
    //left: 0;
    //right: 0;
    text-align: center;
}

@import "assets/app.css";

.van-icon-volume-o {
    font-size: 0.4rem !important;
    padding-right: 0.2rem;
}


.ant-cascader-menu {
    height: 400px !important;
}
</style>
