<template>
    <div class="category" @mouseleave="show=false" @mouseenter="show=true">
        
        <div style="height: 8.0rem;width: 100%;overflow: auto">
            <div v-for="(cate, index) in businessList" :key="index" class="cell"
                 @mouseenter="hoverCate=cate" @click="hoverCate=cate;show=true">
                <div class="title flex-row flex-j-between flex-a-center">
                    <span>{{ cate.name }}</span>
                    <span class="iconfont icon-yougengduo"></span>
                </div>
            </div>
        </div>
        <div v-show="show" class="children">
            <div v-for="(item, i) in hoverCate.professionList||[]" :key="i" class="item" @click="onItemClick(item)">{{
                    item.name
                }}</div>
<!--            <div v-if="hoverCate.name=='全部岗位'" class="item" @click="onItemClick({name: '全部'})">全部</div>-->
            <div v-if="hoverCate.name=='其他'" class="item" @click="onItemClick({name: '其他'})">其他</div>
        </div>
    </div>
</template>

<script>
// category
export default {
    name: "category",
    data() {
        return {
            show: false,
            hoverCate: []
        }
    },
    computed: {
        businessList() {
            return this.$store.state.businessList || [];
        }
    },
    mounted() {
    },
    methods: {
        onItemClick(item) {
            this.show = false;
            this.$emit('select', item.name === "全部" ? {name: ""} : item)
        }
    }
}
</script>

<style scoped lang="less">
.category {
    width: calc(6rem);
    background: #fff;
    //padding: 0.2rem;
    box-sizing: border-box;
    position: relative;
    
    
    & .cell {
        width: 100%;
        height: 0.8rem;
        box-sizing: border-box;
        cursor: pointer;
        color: #666666;
        padding: 0.2rem;
        & .title {
            width: 100%;
            height: 100%;
        }
        &:hover {
            color: #4eabe8;
        }
        
    }
    & .children {
        position: absolute;
        left: calc(100%);
        top: 0;
        width: 6.0rem;
        bottom: 0;
        background-color: #fff;
        z-index: 999;
        color: #333;
        box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.1);
        padding: 0.2rem;
        text-align: left;
        & .item {
            padding: 0.2rem;
            display: inline-block;
            cursor: pointer;
            &:hover {
                color: #4eabe8;
                font-weight: 600;
            }
        }
    }
}
</style>
