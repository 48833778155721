<template>
    <div class="banner">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in list" :key="index">
                <lazy-img class="banner-img" :src="item.ImageURL"/>
            </swiper-slide>
            
        </swiper>
        <div class="left btn tap" @click="onNextBtnClick('left')">
            <span class="iconfont icon-yougengduo"></span>
        </div>
        <div class="right btn tap" @click="onNextBtnClick('right')">
            <span class="iconfont icon-yougengduo"></span>
        </div>
        <div class="swiper-pagination flex-row flex-j-start flex-a-center">
            <img v-for="(item, index) in list" :key="index" :class="`swiper-pagination-img ${activeIndex ==
                        index ? 'active' : ''
                        }`" :src="item.src" :id="index"  @click="onpaginationClick(index)"/>
        </div>
        
    </div>
</template>

<script>
// banner
import {Swiper, SwiperSlide,
    // directive
} from 'vue-awesome-swiper';
import 'swiper/swiper.min.css';
import LazyImg from "@/components/lazy-img";

export default {
    name: "banner",
    components: {
        LazyImg,
        Swiper,
        SwiperSlide
    },
    // directives: {
    //     swiper: directive
    // },
    data() {
        return {
            list: [],
            activeIndex: 0,
            swiperOptions: {
                autoplay: true,
                // pagination: {
                //     el: '.swiper-pagination',
                //     type: 'custom',
                //     clickable: true,
                // },
                // Some Swiper option/callback...
                
                on: {
                    slideChange: () => {
                        this.activeIndex = this.swiper.activeIndex;
                    },
                },
            },
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    mounted() {
        this.loadList();
        // this.swiper.slideTo(3, 1000, false);
    },
    methods: {
        onpaginationClick(e) {
            this.swiper.slideTo(e, 1000, false);
            // if (e.target.src) {
            //     console.log(e.target.id)
            //     this.swiper.slideTo(e.target.id, 100, false);
            // }
        },
        loadList() {
            // path /BaseAdvertising/GetAdvertisingList
            // adType 1 首页 2 培训 3 求职
            const params = {
                adType: 1
            };
            $app.httpGet({
                path: `/BaseAdvertising/GetAdvertisingList`,
                params: params,
                showError: false,
            }).then(res => {
                if (res.ok) {
                    let list = res.data || [];
                    list = list.map(item => {
                        item.src = $app.staticHost + item.ImageURL;
                        return item;
                    })
                    this.list = list;
                }
            })
        },
        onNextBtnClick(item) {
            if (item === 'left') {
                this.swiper.slidePrev();
            } else {
                this.swiper.slideNext();
            }
        }
    },
    watch: {}
}
</script>

<style scoped lang="less">
.banner {
    width: calc(16rem - 0.4rem);
    //background: #fff;
    box-sizing: border-box;
    position: relative;
    & .banner-img {
        height: 6.0rem;
    }
    
    & .swiper-pagination {
        background: #fff;
        padding: 0.2rem;
        border-top: 0.2rem solid #f5f5f5;
        flex-wrap: nowrap;
        overflow: auto;
        & .swiper-pagination-img {
            width: 2.8rem;
            height: 1.2rem;
            margin-right: 0.2rem;
            //box-sizing: border-box;
            transition: all 0.1s ease;
            display: inline-block;
            border: 0.08rem solid #fff;
        }
        
        & .active {
            border: 0.08rem solid orange;
        }
    }
    
    & .btn {
        position: absolute;
        z-index: 99;
        color: #fff;
        height: 6.0rem;
        padding: 0 0.6rem;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease;
        font-size: 0.8rem;
        cursor: pointer;
        & span {
            font-size: 1.0rem;
            transform: rotateZ(180deg);
        }
    }
    & .left {
        left: 0;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) , rgba(0, 0, 0, 0.1));
    }
    & .right {
        right: 0;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) , rgba(0, 0, 0, 0.1));
        transform: rotateZ(180deg);
    }
    &:hover {
        & .btn {
            opacity: 0.8;
        }
    }
}

</style>
