<template>
<!--    <div class="refresh-list">refresh-list</div>-->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
<!--        <van-list-->
<!--            v-model="loading"-->
<!--            :finished="finished"-->
<!--            :immediate-check="false"-->
<!--            finished-text="没有更多了"-->
<!--        >-->
            <slot></slot>
<!--        </van-list>-->
        <div v-if="!finished" class="more" @click="onLoad">点击加载更多</div>
        <div v-else class="more">没有更多了</div>
    </van-pull-refresh>
</template>

<script>
// refresh-list
export default {
    name: "refresh-list",
    data() {
        return {
            loading: false,
            finished: false,
            refreshing: false,
        }
    },
    mounted() {
    },
    methods: {
        onLoad() {
            // 如果是正在下拉刷新中，跳过
            if (this.refreshing) {return 0;}
            this.$emit('load');
        },
        onRefresh() {
            // eslint-disable-next-line no-debugger
            // 如果是正在加载更更多中，跳过
            if (this.loading) {return 0;}
            // 清空列表数据
            this.finished = false;
        
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            // this.onLoad();
            this.$emit('refresh');
            setTimeout(() => {
                this.stop()
            }, 5000)
        },
        // 停止下拉刷新和加载更多
        stop() {
            setTimeout(() => {
                if (this.refreshing) {this.refreshing = false;}
                if (this.loading) {this.loading = false;}
            }, 500)
        },
        // 列表加载结束
        finish() {
            this.finished = true;
        }
    }
}
</script>

<style scoped lang="less">
.refresh-list {
}
.more {
    display: block;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}
</style>
