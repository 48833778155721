import '@/utils/windowScroll';
import Vue from 'vue'
import './utils/app';
import App from './App.vue'
import router from "@/router/router";
import store from './store/index';
import Vant from 'vant';
import 'vant/lib/index.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import {remSize} from "./utils/remSize";
import VueTinymce from "@packy-tang/vue-tinymce";

// 安装组件
Vue.use(VueTinymce)

Vue.use(Antd);
Vue.use(Vant);
Vue.config.productionTip = false
remSize();
$app.getBusinessList();
$app.getAreaList();
const userInfo = $app.getUserInfo() || {};
$app.setStorageSync('userInfo', userInfo);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')



